@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --black-color: #231f20;
  --white-color: #f8fcff;
  --orange-color: #df8d13;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

#contact a {
  color: white;
}

button,
a {
  transition: 0.3s;
}

button:hover,
a:hover,
button:active,
a:active {
  opacity: 0.7;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  padding-top: 64px;
  padding-bottom: 64px;
  transition: 1s;
}

.container-white {
  background-color: var(--white-color);
  color: var(--black-color);
  overflow: hidden;
}

.container-black {
  background-color: var(--black-color);
  color: var(--white-color);
  overflow: hidden;
}

/* .text-white{
    color: var(--white-color);
}

.text-black{
    color: var(--black-color);
} */

.h2 {
  font-weight: 500;
  font-size: 33px;
  line-height: 123.6%;
  text-align: center;
}

.problem {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.problem__item {
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  width: calc(100% / 3 - 20px);
  transition: 0.3s;
}

.problem__item:hover {
  cursor: pointer;
  transform: translateY(-10px);
}

.problem__img {
  margin-top: 50px;
}

.problem__img img {
  width: 64px;
}

.problem__title {
  font-size: 25px;
  margin-top: 20px;
}

.problem__content {
  font-size: 18px;
  margin-top: 20px;
}

#problem-block {
  /* background-image: url("/public/img/pngegg.png"); */
  /* background-repeat: no-repeat; */
  /* background-position: left center; */
}

.about-us-block__row-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.about-us-block-item-left * {
  text-align: left;
}

.about-us-block-item-left .problem__content {
  max-width: 500px;
}

#about-us-block {
  background: #e0e0e0;
}

#heading .container {
  padding: 20px 40px;
}

.map {
  box-shadow: 0px 0px 10px var(--black-color);
}

.about-us-info {
  display: flex;
  justify-content: center;
  /* margin-top: 150px; */
  /* margin-bottom: 86px; */
  flex-wrap: wrap;
}

.about-us-info__item {
  width: calc(100% / 3 - 20px);
  text-align: center;
  border: 1px solid var(--black-color);
  padding: 40px;
}

.about-us-info__item:hover * {
  transform: scale(1.2);
  cursor: pointer;
}

.toggle_content:hover .about-us-info__content,
.toggle_content:active .about-us-info__content,
.toggle_content:hover .about-us-info__title,
.toggle_content:active .about-us-info__title {
  opacity: 0;
  visibility: hidden;
}

.toggle_content {
  position: relative;
}

.second_content {
  opacity: 0;
  transition: 0.3s;
  visibility: hidden;
  font-size: 14px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
}

.toggle_content:hover .second_content,
.toggle_content:active .second_content {
  opacity: 1;
  visibility: visible;
}

.about-us-info__item * {
  transition: 0.3s;
}

.about-us-info__title {
  font-size: 40px;
  font-weight: 700;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.about-us-block-item-left:nth-child(2) h2 {
  text-align: center !important;
}

.image-item img {
  width: 100%;
}
.image-item {
  width: 40%;
}
.image-item figcaption {
  font-weight: 600;
  text-align: center;
}

.about-us-info__content {
  max-width: 80%;
  margin: 10px auto 0;
  font-size: 18px;
}
.about-us-info__item:nth-child(3),
.about-us-info__item:nth-child(2),
.about-us-info__item:nth-child(1) {
  border-top: unset;
}
.about-us-info__item:nth-child(4),
.about-us-info__item:nth-child(5),
.about-us-info__item:nth-child(6) {
  border-bottom: unset;
}
.about-us-info__item:nth-child(1),
.about-us-info__item:nth-child(4) {
  border-left: unset;
}
.about-us-info__item:nth-child(3),
.about-us-info__item:nth-child(6) {
  border-right: unset;
}

.animation-bottom-start {
  transform: translateY(200px);
  opacity: 0;
}

.animation-bottom-end {
  transform: translateY(0px);
  opacity: 1;
}

.swiper {
  margin-top: 40px;
  height: calc(100vh - 80px - 15vh);
}

.swiper-slide {
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}
.swiper-slide:nth-child(1) {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/public/img/bg2.jpg");
}
.swiper-slide:nth-child(2) {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/public/img/bg3.jpg");
}
.swiper-slide:nth-child(3) {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/public/img/bg1.jpg");
}

.swiper-slide img {
  height: 70vh;
}

.h1 {
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  /* height: 200px; */
}

footer {
  background: var(--black-color) !important;
  padding: 80px 0;
}

header button {
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background: rgb(128 128 128 / 80%) !important;
  border: 2px solid var(--white-color);
  margin: 7px !important;
  transition: 0.3s;
}
.swiper-pagination-bullet:active {
  transform: scale(1.2);
}
.swiper-pagination-bullet:hover {
  opacity: 0.7;
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--white-color) !important;
  margin: 0 25px;
  width: 44px !important;
  transition: 0.3s;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(128, 128, 128, 0.415);
  border-radius: 50%;
}
.swiper-button-next:active,
.swiper-button-prev:active {
  background-color: rgba(128, 128, 128);
}
.swiper-heading {
  font-family: "Noto Sans", sans-serif;
  color: white;
  max-width: 60%;

  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
}
.map {
  position: relative;
}
.accordion-title-block .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
body,
html {
  height: 100%;
  min-width: 320px;
}
#root {
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}

main {
  flex: 1 1 auto;
  position: relative;
}

.map::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 5;
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sign-button {
  background: var(--black-color) !important;
  color: var(--white-color) !important;
  height: 56px;
  width: 50% !important;
  left: 50%;
  transform: translateX(-50%);
}

.disable-button {
  background-color: #808080 !important;
  color:  var(--white-color) !important;
  height: 56px;
  width: 50% !important;
  left: 50%;
  transform: translateX(-50%);
}

#sign-up .container {
  max-width: 1000px;
}
.sign-button-modal {
  width: 100% !important;
}

.process-btn {
  background: black !important;
  border-radius: 0 !important;
  box-shadow: 0 0 5px black !important;
  color: white !important;
  font-size: 14px !important;
}

.text-slogan {
  background-color: #fff;
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  width: 100%;
}

#contact {
  height: unset;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  gap: 130px;
}

header .MuiToolbar-root {
  padding: 0;
  margin: 0 40px;
}

.MuiDrawer-modal span {
  width: max-content;
}

#swiper2 {
  display: none;
}

body {
  /* min-width: 960px; */
}

@media (max-width: 1300px) {
  #contact {
    gap: unset;
    justify-content: space-between;
  }
}

@media (max-width: 960px) {
  header .MuiToolbar-root {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .second_content {
    font-size: 10px !important;
  }
  #experience .container {
    padding: 40px 10px;
    /* transform: scale(0.5); */
    /* width: 100%; */
  }
  /* .about-us-info__item {
    padding: 10px;
  }
  .about-us-info__item {
    width: calc(100% / 3 - 0px);
  }
  .about-us-info {
    justify-content: space-between;
  }
  .about-us-info__content {
    font-size: 3vw;
  }
  .about-us-info__title {
    font-size: 5vw;
  }
  .about-us-info__content {
    hyphens: auto;
  } */

  .about-us-info__content {
    hyphens: auto;
  }
  .about-us-info {
    justify-content: space-between;
  }
  .about-us-info__item {
    width: calc(100% / 2);
    border: unset;
    padding: 20px;
    padding-right: 0;
  }
  .about-us-info__item:nth-child(2n - 1) {
    border-right: 1px solid var(--black-color);
    border-bottom: 1px solid var(--black-color);
    padding-left: 0;
  }
  .about-us-info__item:nth-child(5) {
    border-bottom: unset;
  }
  .about-us-info__item:nth-child(2n) {
    border-left: 1px solid var(--black-color);
    border-bottom: 1px solid var(--black-color);
  }
  .about-us-info__item:nth-child(5),
  .about-us-info__item:nth-child(6) {
    border-bottom: unset;
  }
  .problem__item {
    padding: 15px;
    width: calc(100% / 2 - 20px);
  }
}

/*
@media (max-width: 760px) {
  .problem {
    display: none;
  }
  #swiper2 {
    display: block;
    height: max-content !important;
  }
  #swiper2 .swiper-slide {
    height: max-content !important;
    background: unset;
  }
  #swiper2 .problem__item {
    width: 100%;
    height: 350px;
  }
  #swiper2 .swiper-slide img {
    height: unset;
  }
  #swiper2 .problem__img img {
    margin: 0 auto;
    width: 50px;
  }
  .swiper-slide,
  .swiper {
    height: 300px !important;
  }
  #contact {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: unset;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #contact * {
    align-items: center;
    justify-content: center;
  }
  .swiper {
    height: 50vh;
  }
  .problem__content:nth-child(2) {
    height: 280px;
    overflow-y: scroll;
  }
  .problem__content:nth-child(2) * {
    text-align: left;
  }
  .problem__content::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  .problem__content::-webkit-scrollbar-thumb {
    background-color: var(--black-color);
  }
  .about-us-block__row-first * {
    text-align: center;
  }
  
}
@media (max-width: 540px) {
  .about-us-info__item {
    padding: 5px;
  }
  .about-us-info__content {
    margin: 0 auto;
    hyphens: auto;
  }
  #experience .container {
    padding-left: 0;
    padding-right: 0;
  } */
/* .problem__item {
    padding: 15px;
    width: calc(100% - 20px);
  }
  .problem {
    justify-content: center;
  }
  .about-us-info__item {
    width: calc(100% - 20px);
    border: unset !important;
    border-bottom: 1px solid var(--black-color) !important;
  } */
/* } */

#mob-menu {
  bottom: 0px;
  position: fixed;
  top: unset !important;
  width: 100%;
  left: 0px;
  background-color: white;
  z-index: 999;
  /*border-top: 3px solid #000;*/
}
#mob-menu img {
  width: 30px;
  height: 30px;
}

#mob-menu img {
  width: 30px;
  height: 30px;
}

#mob-menu [role="button"],
#mob-menu .MuiListItemIcon-root {
  justify-content: center;
}

#mob-menu [role="button"] span {
  text-align: center;
}

#mob-menu img:active {
  filter: invert(1);
  background: white;
  border-radius: 5px;
}

#mob-menu ul {
  width: 100%;
}

#mob-menu:active {
  animation: active 5s ease;
}

@keyframes active {
  100% {
  }
}

@media (max-width: 760px) {
  #sign-up button {
    width: 100% !important;
    height: 45px;
  }
  .problem__content {
    font-size: 16px;
  }
  .about-us-info__title {
    font-size: 25px;
  }
  .problem__content * {
    font-size: 16px;
    text-align: center;
  }
  #problem-block .swiper-slide *:hover,
  #problem-block .swiper-slide *:active {
    transform: unset !important;
    cursor: unset !important;
  }
  .about-us-info__content {
    max-width: 80%;
    margin: 5px auto 0;
    font-size: 16px;
  }
  .container {
    padding: 40px 20px;
  }
  .about-us-block__row-first {
    flex-direction: column;
    gap: 25px;
  }
  #sign-up .MuiBox-root {
    margin-top: 0px;
  }
  #sign-up .h2 {
    margin-bottom: 20px;
  }
  #contact {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: unset;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #contact * {
    align-items: center;
    justify-content: center;
  }
  .problem {
    display: none;
  }
  #swiper2 {
    display: block;
    height: max-content !important;
  }
  #swiper2 .swiper-slide {
    height: max-content !important;
    background: unset;
  }
  #swiper2 .problem__item {
    width: 100%;
    height: unset;
  }
  #swiper2 .swiper-button-prev,
  #swiper2 .swiper-button-next {
    display: none;
  }
  #problem-block .swiper {
    overflow: visible;
  }
  #problem-block {
    position: relative;
  }
  #problem-block .swiper-pagination {
    position: absolute;
    bottom: -40px;
  }
  #problem-block * {
    overflow: visible !important;
    height: max-content !important;
  }
  #swiper2 .swiper-slide img {
    height: unset;
  }
  #swiper2 .problem__img img {
    margin: 0 auto;
    width: 50px;
    height: 50px !important;
  }
  .hidden {
    display: none;
  }
  .swiper-slide,
  .swiper {
    /* height: 150px !important; */
    height: auto !important;
    width: 100% !important;
    aspect-ratio: 1.7;
  }
  header .MuiToolbar-root {
    padding: 0;
    margin: 0 20px;
  }
  .css-1aeycle {
    width: 90% !important;
    padding: 0px !important;
  }
  .css-1aeycle .css-1gbm8pl {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #problem-block .container {
    padding-bottom: 50px;
  }
  header {
    height: 60px !important;
  }
  .swiper {
    margin-top: 60px;
  }
  #swiper2 {
    margin-top: 20px;
  }
  .h1 {
    font-size: 24px;
  }
  .h2 {
    font-size: 26px;
    text-align: center;
  }
  .text-slogan {
    font-size: 16px;
  }
  h1 span {
    display: none;
  }
}

@media (max-width: 480px) {
  /* example1 */
  /* .image-item {
    width: 100%;
  }
  .image-item img {
    aspect-ratio: 1.7;
    object-fit: cover;
  }
  .image-container {
    gap: 20px;
  } */

  .image-item {
    width: 45%;
  }
  .image-item img {
  }
  .image-container {
    margin-top: 0;
  }
  .image-container {
    gap: 20px;
  }

  .swiper-heading {
    font-size: 24px;
    line-height: 130%;
  }
  .swiper-button-prev {
    left: -20px !important;
    right: unset !important;
  }
  .swiper-button-next {
    right: -20px !important;
    left: unset !important;
  }
}

#mob-menu .MuiPaper-root {
  width: 70%;
}
#mob-menu * {
  font-size: 30px;
}
ymaps-map {
  -ms-touch-action: auto !important;
  touch-action: auto !important;
}
